import React from "react"
import cx from "classnames"

import Expert from "~/models/expert"
import Image from "~/ui/elements/image/image"

type Props = {
  className?: string
  expert: Expert
}

function ExpertCard({ className, expert }: Props) {
  return (
    <div className={cx("p-4 bg-white rounded-lg shadow-card-light", className)}>
      <div className="flex gap-3 lg:gap-x-4 mb-4 items-center">
        <div className="w-[56px] lg:h-[56px] rounded-full flex-shrink-0 overflow-hidden z-10 relative">
          <Image
            className="block"
            gatsbyImageData={expert.avatar}
            alt={expert.name}
          />
        </div>
        <div>
          <div className="mb-1 text-2xl leading-none text-font-1 font-medium">
            {expert.name}
          </div>
          <div className="text-sm text-font-1 font-medium">{expert.title}</div>
        </div>
      </div>
      <p className="text-lg text-font-2">{expert.description}</p>
    </div>
  )
}

export default ExpertCard
