import { ContentfulAsset } from "../../types/graphql-types"

export default class Expert {
  id: string
  avatar: ContentfulAsset
  name: string
  title: string
  description: string

  constructor(expert: any) {
    this.id = expert.id
    this.avatar =
      expert.avatar?.localFile?.childImageSharp?.gatsbyImageData ||
      expert.avatar?.gatsbyImageData
    this.name = expert.name
    this.title = expert.title
    this.description = expert.description?.description! || ""
  }
}
