import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import LocalImage from "~/ui/elements/image/local"
import Casino from "~/models/casino"
import Image from "~/ui/elements/image/image"
import Stars from "~/ui/elements/review/stars"
import PartnerLink from "~/ui/elements/button/partner-link"
import Info from "~/ui/identity/icon/info"
import useBonusPopup from "~/hooks/useBonusPopup"

type Props = {
  className?: string
  casino: Casino
}

ComparisonCard.defaultProps = {}

function ComparisonCard({ className, casino }: Props) {
  const { handleActivation } = useBonusPopup()

  function onClick(e: React.MouseEvent<HTMLElement>) {
    if (casino.bonuses.length == 0) return

    e.preventDefault()
    handleActivation(casino.bonuses[0], e)
  }

  return (
    <div className={cx("shadow-casino-card rounded-xl py-6 px-4", className)}>
      <div className="relative flex mb-5 justify-between items-start">
        <img
          src="/images/general/indian-flag.svg"
          alt=""
          className="w-6"
          decoding="async"
        />
        <div className="w-[70px] overflow-hidden block rounded-xl z-10 relative">
          <Image
            className="block"
            gatsbyImageData={casino.logo}
            alt={casino.name}
          />
        </div>
        <button onClick={e => onClick(e)}>
          <Info width={16} />
        </button>
      </div>

      <div className="flex justify-center gap-2 mb-3">
        <Stars rating={casino.rating} />
        <div>
          <span className="mr-1 text-2xl leading-none text-font-1 font-bold">
            {casino.rating.toFixed(1)}
          </span>
          <span className="mr-1 text-lg leading-none text-font-2">/</span>
          <span className="text-lg leading-none text-font-2">5</span>
        </div>
      </div>

      <div className="mb-2 text-sm text-font-1 text-center">{casino.name}</div>

      {casino.bonuses.length > 0 && (
        <div className="mb-3">
          <div className="text-xs text-center text-font-3">Bonus</div>
          <div className="text-2xl text-center text-font-1 font-bold">
            {casino.bonuses[0].value}
          </div>
        </div>
      )}

      <div className="mb-3">
        <div className="mb-2 text-center text-font-3">Payment Methods</div>
        <div className="text-center">
          {casino.paymentMethods
            .filter((_, index) => index < 4)
            .map((method, index) => {
              return (
                <LocalImage
                  key={index}
                  className="inline-block mr-3 last:mr-0 mb-3"
                  filename={`payment/${method
                    .toLowerCase()
                    .replace(/\s/g, "")}.png`}
                  alt={method}
                  width={48}
                />
              )
            })}
        </div>
      </div>

      <div>
        <PartnerLink type="primary" casino={casino} className="mb-2 mt-2">
          Visit Casino
        </PartnerLink>
        {casino.page && (
          <Link
            to={casino.page?.url.pathname}
            className="block text-center text-sm text-blue-600 font-medium"
          >
            Review
          </Link>
        )}
      </div>
    </div>
  )
}

export default ComparisonCard
