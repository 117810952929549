import { ContentfulCasinoCollection } from "../../types/graphql-types"
import Casino from "./casino"

export default class CasinoCollection {
  id: string
  type: string
  casinos: Casino[]

  constructor(collection: ContentfulCasinoCollection) {
    this.id = collection.id
    this.type = collection.type || ""

    this.casinos = collection.casinos
      ? collection.casinos!.map((casino: any) => new Casino(casino))
      : []
  }
}
