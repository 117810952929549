import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CardGridBlock from "~/ui/compositions/generic/card-grid"
import ParagraphsBlock from "~/ui/compositions/generic/paragraphs"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import CasinoComparisonBlock from "~/ui/compositions/casino/comparison"
import ExpertsBlock from "~/ui/compositions/generic/experts"
import ChecklistBlock from "~/ui/compositions/generic/checklist"

import { CasinoListQuery, ContentfulPage } from "../../../types/graphql-types"

type Props = {
  data: CasinoListQuery
}

function ComparisonPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))
  const page = new Page(data.contentfulPage as ContentfulPage)

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map((block) => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
            <React.Fragment>
            <PageCoverBlock {...block.props} />
            <div className="mb-2 mt-2">
              <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                <Breadcrumbs breadcrumbs={page.breadcrumbs} />
              </div>
            </div>
            </React.Fragment>
            }
            {block.name == "CasinoComparison" && (
              <CasinoComparisonBlock {...block.props} />
            )}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "Checklist" && <ChecklistBlock {...block.props} />}
            {block.name == "Experts" && <ExpertsBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default ComparisonPage

export const query = graphql`
  query Comparison {
    contentfulPage(url: { pathname: { eq: "/compare/" } }) {
      ...PageFields
    }
  }
`
