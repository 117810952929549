import { useStaticQuery, graphql } from "gatsby"
import React from "react"

import Expert from "~/models/expert"

import TitledSection from "~/ui/layout/titled-section"
import ExpertCard from "~/ui/components/expert/card"

import { ContentfulExpert } from "../../../../types/graphql-types"

type Props = {
  title: string
  description: string
  background: "yellow" | "white"
}

ExpertList.defaultProps = {
  background: "yellow",
}

function ExpertList({ title, description, background }: Props) {
  const { allContentfulExpert } = useStaticQuery(
    graphql`
      query ExpertList {
        allContentfulExpert(limit: 3) {
          nodes {
            ...ExpertFields
          }
        }
      }
    `
  )

  const experts = allContentfulExpert.nodes.map((casino: ContentfulExpert) => {
    return new Expert(casino)
  })

  return (
    <TitledSection
      title={title}
      description={description}
      background={background}
    >
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {experts.map((expert: Expert, index: number) => {
          return <ExpertCard key={index} expert={expert} />
        })}
      </div>
    </TitledSection>
  )
}

export default ExpertList
