import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

function Info(props: Props) {
  return (
    <svg
      width={props.width}
      className={props.className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14.5455C11.615 14.5455 14.5455 11.615 14.5455 8C14.5455 4.38505 11.615 1.45455 8 1.45455C4.38505 1.45455 1.45455 4.38505 1.45455 8C1.45455 11.615 4.38505 14.5455 8 14.5455ZM9.27273 4.54788C9.27273 4.84919 9.16921 5.10638 8.96118 5.31783C8.75365 5.53037 8.50343 5.63636 8.21102 5.63636C7.91762 5.63636 7.66691 5.53037 7.45693 5.31783C7.24743 5.10638 7.14243 4.84919 7.14243 4.54788C7.14243 4.2471 7.24743 3.98937 7.45693 3.77523C7.66642 3.56108 7.91762 3.45455 8.21102 3.45455C8.50343 3.45455 8.75365 3.56162 8.96118 3.77523C9.16921 3.98937 9.27273 4.2471 9.27273 4.54788ZM9.16863 11.7212C8.85632 11.8712 8.60657 11.9854 8.42064 12.064C8.23471 12.1427 8.01837 12.1818 7.77204 12.1818C7.39376 12.1818 7.09945 12.0693 6.88954 11.8452C6.67962 11.6206 6.57466 11.3355 6.57466 10.9901C6.57466 10.8561 6.58237 10.7186 6.59822 10.5784C6.61407 10.4382 6.63935 10.2798 6.67405 10.1031L7.06475 8.42205C7.09945 8.26103 7.12901 8.10834 7.15257 7.964C7.17656 7.82069 7.18813 7.68885 7.18813 7.57004C7.18813 7.35535 7.15172 7.20527 7.07889 7.12085C7.00606 7.03643 6.86726 6.9937 6.6612 6.9937C6.5601 6.9937 6.45642 7.0135 6.35061 7.05154C6.24437 7.08958 6.18182 6.64248 6.18182 6.64248C6.43757 6.51585 6.68219 6.40746 6.9161 6.31731C7.15 6.22715 7.37106 6.18182 7.58012 6.18182C7.95582 6.18182 8.24585 6.29229 8.44934 6.51324C8.65283 6.73419 8.75479 7.0208 8.75479 7.37411C8.75479 7.44706 8.74794 7.57577 8.7338 7.75972C8.71966 7.94419 8.69353 8.11303 8.6554 8.26676L8.26642 9.94159C8.23471 10.076 8.20601 10.2298 8.18116 10.4028C8.15546 10.5747 8.14304 10.706 8.14304 10.7941C8.14304 11.0166 8.18373 11.1683 8.26556 11.249C8.34781 11.3298 8.48961 11.3699 8.69096 11.3699C8.78564 11.3699 8.89316 11.3496 9.01269 11.3095C9.13221 11.2694 9.16863 11.7212 9.16863 11.7212Z"
        fill="#AE99A8"
      />
    </svg>
  )
}

export default Info
